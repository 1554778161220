import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { makeStyles } from '@material-ui/styles';
import { Box, Grid, Typography } from '@material-ui/core';
import Particles from 'react-particles-js';
import Typewriter from 'typewriter-effect/dist/core';
import LayoutWrapper from '../components/layout';
import LocalizedLink from '../utils/localized-link';
import { withForceUpdate } from '../i18n/with-force-update';
import { LatestProjects } from '../components/latest-projects';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const useStyles = makeStyles((theme) => ({
  skillsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: `center`,
    justifyContent: `center`,
  },
  singleSkill: {
    // border: "1px solid #F1F1F1",
    padding: '10px',
    margin: '4px',
    borderRadius: '4px',
    textTransform: 'uppercase',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    width: '150px',
    minHeight: '150px',
    cursor: `pointer`,
  },
  belovedSkill: {
    background: `linear-gradient(134deg, #d4a730 50%, #dfac43 50%)`,
    fontWeight: `bold`,
  },
  belovedColor: {
    // color: `#dfac47`,
    padding: `5px`,
    borderRadius: `4px`,
  },
  initialNotice: {
    textAlign: `center`,
  },
  textField: {
    display: 'flex',
    borderColor: '#F1F1F1',
    color: '#F1F1F1',
    '&:focus': {
      display: 'none',
      borderColor: '#dfac23',
    },
    margin: '30px auto',
    maxWidth: `100%`,
    width: `550px`,
  },
  skillLogo: {
    width: '100px',
    margin: `auto`,
  },
  skillTitle: {
    marginTop: '10px',
  },
  hidden: {
    display: 'none',
  },
  searchFieldIcon: {
    marginRight: '10px',
    color: '#dfab23',
  },
  experiencesWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: `center`,
    justifyContent: `center`,
  },
  experienceWrapper: {
    background: `rgba(0,0,0,0.12)`,
    width: `350px`,
    maxWidth: `28%`,
    margin: `10px`,
    borderRadius: `5px`,
    textAlign: `center`,
    '@media (max-width: 768px)': {
      maxWidth: `100%`,
    },
  },
  articleWrapper: {
    background: `rgba(0,0,0,0.12)`,
    width: `350px`,
    maxWidth: `25%`,
    margin: `10px`,
    borderRadius: `5px`,
    textAlign: `center`,
    position: `relative`,
    '@media (max-width: 768px)': {
      maxWidth: `100%`,
    },
  },
  articleDate: {
    position: `absolute`,
    top: 4,
    right: 5,
    zIndex: 5,
    color: `white`,
    padding: `5px`,
    background: `rgba(0,0,0,0.7)`,
  },
  experienceTitle: {
    fontWeight: 600,
    whiteSpace: `nowrap`,
    textTransform: `uppercase`,
    textOverflow: `ellipsis`,
    overflow: `hidden`,
    padding: `10px 5px`,
  },
}));

const VerticalLine = () => (
  <div
    css={{
      background: `#dfac47`,
      width: `4px`,
      height: `50px`,
    }}
  />
);

const TextOverImage = ({ image, text, linkto }) => (
  <div>
    <LocalizedLink
      to={linkto}
      css={{
        maxWidth: `300px`,
        position: `relative`,
        display: `flex`,
        margin: `5px auto`,
        ':hover .overlay': {
          background: `rgba(0,0,0,.62)`,
        },
      }}
    >
      {image}
      <div
        className="overlay"
        css={{
          position: `absolute`,
          top: `0`,
          left: `0`,
          right: `0`,
          bottom: `0`,
          background: `rgba(0,0,0,.32)`,
          maxWidth: `300px`,
          maxHeight: `200px`,
          transition: `background .1s ease-in`,
        }}
      />
      <h3
        css={{
          position: `absolute`,
          top: `50%`,
          left: `0`,
          right: `0`,
          textAlign: `center`,
          textTransform: `uppercase`,
          fontWeight: `400`,
        }}
      >
        {text}
      </h3>
    </LocalizedLink>
  </div>
);
const Column = ({ children, width, relative, stl }) => {
  return (
    <div
      css={{
        ...stl,
        width,
        position: relative ? `relative` : `auto`,
        '@media (max-width: 768px)': {
          width: `100%`,
        },
        padding: `30px 10px`,
      }}
    >
      {children}
    </div>
  );
};

const SectionTitle = ({ children, title }) => {
  return (
    <h2
      css={{
        width: `100%`,
        textAlign: `center`,
        fontFamily: `LatoLatinWebLight`,
        textTransform: `uppercase`,
        fontWeight: `200`,
        color: `rgba(212, 212, 212, 0.83)`,
      }}
    >
      {children}
    </h2>
  );
};

const Section = ({ children, reverse, reverseMobile, stl }) => {
  return (
    <Grid
      container
      direction={{
        xs: `column-${reverse && '-reverse'}`,
        sm: `column-${reverse && '-reverse'}`,
        md: `row-${reverse && '-reverse'}`,
      }}
      sx={{
        ...stl,
      }}
    >
      {children}
    </Grid>
  );
};

const IndexPage = ({ t, pageContext: { language }, data }) => {
  const classes = useStyles();
  const [headerBackground, setHeaderBackground] = useState('#292f33');

  const listenScrollEvent = (evt) => {
    if (window.scrollY > 400) {
      // First page has been scrolled
      setHeaderBackground(null);
      // document.body.style.backgroundColor = '#';
    } else {
      setHeaderBackground('#292f33');
      // document.body.style.backgroundColor = '#292f33';
    }
  };

  useEffect(() => {
    new Typewriter('#function', {
      strings: [t('front-end developer'), t('back-end developer'), t('software engineer')],
      autoStart: true,
      loop: true,
    });
    // document.body.style.backgroundColor = '#292f33';
    window.addEventListener('scroll', listenScrollEvent);
  }, [t]);

  return (
    <LayoutWrapper
      lang={language}
      title={t('Home')}
      noHorizontalPadding
      noVerticalPadding
      headerBackground={headerBackground}
      hideTopPadding
      beforeMain={
        <div
          css={{
            height: `100vh`,
            width: `100%`,
            display: `flex`,
            background: `#292f33`,
            position: `relative`,
          }}
        >
          <div
            css={{
              position: `absolute`,
              top: `0`,
              bottom: `0`,
              left: `0`,
              right: `0`,
              display: `flex`,
              alignItems: `center`,
              flexDirection: `column`,
              justifyContent: `center`,
            }}
          >
            <h1 css={{ fontWeight: `100`, textTransform: `uppercase` }}> Hugues BUREAU </h1>
            <h2 css={{ fontWeight: `100`, color: `#F1F1F1` }}>
              Freelance <span id="function" css={{ color: `#dfac47` }} />
            </h2>
          </div>
          <Particles
            css={{ width: `100%` }}
            params={{
              particles: {
                number: {
                  value: 60,
                  density: {
                    enable: true,
                    value_area: 1500,
                  },
                },
                line_linked: {
                  enable: true,
                  opacity: 0.02,
                },
                move: {
                  direction: 'right',
                  speed: 0.25,
                },
                size: {
                  value: 1,
                },
                opacity: {
                  anim: {
                    enable: true,
                    speed: 1,
                    opacity_min: 0.15,
                  },
                },
              },
              interactivity: {
                events: {
                  onclick: {
                    enable: true,
                    mode: 'push',
                  },
                },
                modes: {
                  push: {
                    particles_nb: 1,
                  },
                },
              },
              retina_detect: true,
            }}
          />
        </div>
      }
    >
      <div css={{ display: `flex`, flexDirection: `column` }}>
        <Section>
          <div
            css={{
              display: `flex`,
              flexDirection: `column`,
              alignItems: `center`,
              margin: 'auto',
            }}
          >
            <GatsbyImage
              css={{ borderRadius: `9999px`, float: `none`, margin: `10px auto` }}
              image={getImage(data?.selfCover)}
              alt={t('Experiences')}
              loading="eager"
            />
            <VerticalLine />
            <h3 css={{ margin: `10px auto`, fontWeight: `200` }}>{t("Hi, I'm Hugues!")}</h3>
            <h4 css={{ margin: `10px auto`, fontWeight: `200`, color: `#949494` }}>
              {t("I'm a freelance fullstack developer!")}
            </h4>
            <VerticalLine />
            <h4 css={{ margin: `10px auto`, fontWeight: `200` }}>{t("Let's get to know each other !")}</h4>
            <div
              css={{
                display: `flex`,
                width: `100%`,
                flexDirection: `row`,
                justifyContent: `space-evenly`,
                '@media (max-width: 904px)': {
                  flexDirection: `column`,
                },
              }}
            >
              <TextOverImage
                image={
                  <GatsbyImage
                    css={{ maxWidth: `100%`, margin: `auto` }}
                    image={getImage(data?.experiencesCover)}
                    alt={t('Experiences')}
                  />
                }
                text={t('Experiences')}
                linkto="/about/experiences"
              />
              <TextOverImage
                image={
                  <GatsbyImage
                    css={{ maxWidth: `100%`, margin: `auto` }}
                    image={getImage(data?.skillsCover)}
                    alt={t('Skills')}
                  />
                }
                text={t('Skills')}
                linkto="/about/skills"
              />
              <TextOverImage
                image={
                  <GatsbyImage
                    css={{ maxWidth: `100%`, margin: `auto` }}
                    image={getImage(data?.certificationsCover)}
                    alt={t('Certifications')}
                  />
                }
                text={t('Certifications')}
                linkto="/about/certifications"
              />
            </div>
          </div>
        </Section>
        <Box sx={{ padding: `30px 10px`, maxWidth: '800px', margin: 'auto' }}>
          <Grid
            container
            direction="column"
            spacing={{ md: 3, sm: 3, xs: 0 }}
            // sx={{ padding: `30px 10px`}}
          >
            <Grid item>
              <SectionTitle> {t('Expertise')} </SectionTitle>
            </Grid>
            {data.allStrapiScopes.edges.map((scopeNode, itr) => (
              <Grid item key={`${itr}-scope`} sx={{ margin: '30px 0' }}>
                <Grid
                  container
                  direction={{ xs: 'column', sm: 'row', md: 'row' }}
                  spacing={{ md: 4, sm: 0, xs: 0 }}
                  alignItems="center"
                >
                  <Grid item md={4} sx={{ textAlign: 'center' }}>
                    <GatsbyImage
                      css={{ maxWidth: '300px', width: `100%`, margin: `auto`, objectFit: 'contain' }}
                      image={getImage(scopeNode.node.cover.localFile)}
                      alt={scopeNode.node.name}
                    />
                  </Grid>
                  <Grid item md={8} sx={{ padding: { xs: '20px' } }}>
                    <Typography variant="h5">{scopeNode.node.name}</Typography>
                    <Typography variant="body1" color="#b9b9b9">
                      {scopeNode.node.description}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Section stl={{ background: `rgba(0,0,0,0.1)` }} reverse reverseMobile={false}>
          <Column
            width="100%"
            stl={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              alignItems: `center`,
              justifyContent: `center`,
            }}
          >
            <SectionTitle> {t('L❤️ved stacks')} </SectionTitle>
            {data?.lovedSkills.edges.map((skillNode, itr) => (
              <div className={classes.singleSkill} color="primary" key={skillNode.node.name}>
                {skillNode.node.skillThumbnail && (
                  <div key={`stack-${itr}`} className={classes.skillLogo} css={{ width: `75px` }}>
                    <GatsbyImage
                      imgStyle={{ objectFit: 'contain' }}
                      image={getImage(skillNode.node.skillThumbnail.localFile)}
                      alt={skillNode.node.name}
                    />
                  </div>
                )}
                <div className={classes.skillTitle}> {skillNode.node.name} </div>
              </div>
            ))}
            <div css={{ width: `100%`, textAlign: `center` }}>
              <LocalizedLink css={{ display: `inline-block`, margin: `auto` }} to="/about/skills">
                {t('See more')}
              </LocalizedLink>
            </div>
          </Column>
        </Section>
        <Section>
          <Column width="100%">
            <SectionTitle> {t('Latest Projects')} </SectionTitle>
            <LatestProjects projects={data?.latestExperiences.edges} />
          </Column>
        </Section>
      </div>
    </LayoutWrapper>
  );
};

export default withForceUpdate(IndexPage);

export const query = graphql`
  query HomePageQuery($language: String) {
    ...FullSettings
    ...ActivePositions
    ...LovedSkills
    ...LatestExperiences
    allStrapiScopes(filter: { locale: { eq: $language } }) {
      edges {
        node {
          name
          description
          cover {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 500, placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP])
              }
            }
          }
        }
      }
    }
    whatIDoCover: file(relativePath: { eq: "home_01.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 2500, placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP])
      }
    }
    belovedStacksCover: file(relativePath: { eq: "home_02.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 2500, placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP])
      }
    }
    customersCover: file(relativePath: { eq: "home_03.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 2500, placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP])
      }
    }
    selfCover: file(relativePath: { eq: "self-cover.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 300, height: 300, placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP])
      }
    }
    experiencesCover: file(relativePath: { eq: "experiences_illustration.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 500, placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP])
      }
    }
    skillsCover: file(relativePath: { eq: "skills_illustration.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 500, placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP])
      }
    }
    certificationsCover: file(relativePath: { eq: "certifications_illustration.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 500, placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP])
      }
    }
  }
`;
