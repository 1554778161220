import React, { useState, useEffect, useMemo } from 'react';
import { makeStyles } from '@material-ui/styles';
import LocalizedLink from '../utils/localized-link';
import clsx from 'clsx';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Box, Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  experiencesWrapper: {},
  experienceWrapper: {
    background: `rgba(0,0,0,0.12)`,
    width: `100%`,
    // maxWidth: `28%`,
    // margin: `10px`,
    textAlign: `center`,
    display: `block`,
    '@media (max-width: 768px)': {
      maxWidth: `100%`,
    },
  },
  experienceTitle: {
    fontWeight: 600,
    whiteSpace: `nowrap`,
    textTransform: `uppercase`,
    textOverflow: `ellipsis`,
    overflow: `hidden`,
    padding: `10px 5px`,
  },
  preview: {},
  list: {
    display: `flex`,
    flexDirection: `column`,
    width: `200px`,
    '@media (max-width: 768px)': {
      flexDirection: `row`,
      width: `100%`,
      overflowX: `auto`,
    },
  },
  active: {
    background: `#dfac47`,
  },
  progress: {
    width: `100%`,
    height: `15px`,
    background: `#dfac47`,
  },
  previewTitle: {
    position: `absolute`,
    fontWeight: `bold`,
    left: 0,
    right: 0,
    bottom: 0,
    background: `rgba(0, 0, 0, 0.75)`,
    padding: `20px`,
  },
}));

export const LatestProjects = ({ projects }) => {
  const classes = useStyles();
  const [current, setCurrent] = useState(0);
  const [auto, setAuto] = useState(true);

  const next = () => {
    const isNextEmpty = current === projects.length - 1;
    const nextIdx = isNextEmpty ? 0 : current + 1;
    setCurrent(nextIdx);
  };

  useEffect(() => {
    setCurrent(0);
  }, [projects, setCurrent]);

  useEffect(() => {
    if (!auto) return;
    const interval = setInterval((_) => {
      next();
    }, 5000);

    return (_) => clearInterval(interval);
  }, [auto, current, next]);
  return (
    <Grid
      container
      direction={{ xs: 'column', sm: 'column', md: 'row' }}
      alignItems="flex-start"
      justifyContent="center"
      sx={{
        flexWrap: 'wrap',
      }}
    >
      <Grid item md xs={12} sm={12}>
        <Box
          sx={{
            flexGrow: 1,
            position: `relative`,
            minHeight: `300px`,
            '@media (max-width: 768px)': {
              width: `100%`,
            },
          }}
        >
          <LocalizedLink to={`/about/experiences#${projects[current] && projects[current].node.slug}`}>
            <div css={{ width: `100%`, maxHeight: `700px`, overflow: `hidden` }}>
              {projects[current] && projects[current].node.experienceThumbnailLarge && (
                <GatsbyImage
                  imgStyle={{ objectFit: 'contain' }}
                  image={getImage(projects[current].node.experienceThumbnailLarge.localFile)}
                  alt={projects[current].node.name}
                />
              )}
            </div>
            <Box
              sx={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                height: '60px',
                background: 'rgba(0,0,0,0.5)',
              }}
            />
            <Box
              sx={{
                '&::after': {
                  content: "''",
                  width: '50px',
                  height: '2px',
                  background: '#dfac47',
                  position: 'absolute',
                  bottom: '15px',
                  left: '10px',
                },
                fontFamily: 'LatoLatinWeb',
                color: 'white',
                height: '50px',
                fontSize: '22px',
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                paddingLeft: '10px',
              }}
            >
              {projects[current].node.name}
            </Box>
          </LocalizedLink>
        </Box>
      </Grid>
      <Grid item md={2} xs={12} sm={12}>
        <div className={classes.list}>
          {projects.map((elt, itr) => (
            <div
              key={elt.node.slug}
              className={clsx(classes.experienceWrapper, {
                [classes.active]: projects[current] && projects[current].node.slug === elt.node.slug,
              })}
              onClick={() => {
                setAuto(false);
                setCurrent(itr);
              }}
            >
              <div css={{ width: `100%`, padding: `3px`, position: 'relative' }}>
                {elt.node.experienceThumbnail && (
                  <GatsbyImage
                    imgStyle={{ objectFit: 'contain' }}
                    image={getImage(elt.node.experienceThumbnail.localFile)}
                    alt={elt.node.name}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      </Grid>
    </Grid>
  );
};
